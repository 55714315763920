export const SERVICE_URL: string = process.env.SERVICE_URL;
export const SITE_URL = process.env.SITE_URL;
export const BLOB_URL: string = process.env.BLOB_URL;
export const DEFAULT_CAUSE_URL: string = process.env.DEFAULT_CAUSE_URL;

export const ALERT_TYPE_ERROR = "Error";
export const ALERT_TYPE_SUCCESS = "Success";

export const STORAGE_CODE_DETAILS = "STORAGE_CODE_DETAILS";
export const STORAGE_DONATIONS = "STORAGE_DONATIONS";
export const STORAGE_CART = "TB_CART";
