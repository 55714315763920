import React, { useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import {
  fetchCodeDetails,
  fetchCartDetails,
} from "../../../shared/utilities/actions";
import { CodeDetails, DefaultCodeDetails } from "../../../shared/types";
import { CartButton } from "../components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export interface MainProps {
  hideOnSpend: boolean;
  hideOnBuy: boolean;
  hideOnEmpty: boolean;
}

export const Main = ({
  hideOnSpend,
  hideOnBuy,
  hideOnEmpty,
}: MainProps): JSX.Element | null => {
  const [busy, setBusy] = useState<boolean>(true);
  const [codeDetails, setCodeDetails] = useState<CodeDetails>({
    ...DefaultCodeDetails,
  });
  const [cartItemCount, setCartItemCount] = useState<number>(0);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const fetchData = async () => {
      setBusy(true);
      fetchCodeDetails(codeDetails.themeId, setCodeDetails);
      fetchCartDetails(setCartItemCount);
      setBusy(false);
    };
    fetchData();
  }, []);

  if (
    (codeDetails.valid && hideOnSpend) ||
    (cartItemCount > 0 && hideOnBuy) ||
    (cartItemCount == 0 && hideOnEmpty)
  ) {
    return null;
  } else {
    return <CartButton cartItemCount={cartItemCount} />;
  }
};
