import * as consts from "./consts";
import axios from "axios";
import { CartDetails, CodeDetails } from "../types";

export const validateCode = async (
  codeDetails: CodeDetails
): Promise<CodeDetails> => {
  let response = await axios.get(
    `${consts.SERVICE_URL}/codes/${codeDetails.code}/status/${codeDetails.email}/${codeDetails.name}`,
    {}
  );
  if (response.data.valid) {
    localStorage.setItem(
      consts.STORAGE_CODE_DETAILS,
      JSON.stringify(response.data)
    );
  } else {
    localStorage.removeItem(consts.STORAGE_CODE_DETAILS);
  }
  return response.data;
};

export const fetchCodeDetails = (
  currentThemeId: string,
  setCodeDetails: (codeDetails: CodeDetails) => void
): string => {
  let themeId = currentThemeId;
  let storageCodeDetails = localStorage.getItem(consts.STORAGE_CODE_DETAILS);
  if (storageCodeDetails) {
    let currentCodeDetails: CodeDetails = JSON.parse(storageCodeDetails);
    themeId = currentCodeDetails.themeId;
    setCodeDetails(currentCodeDetails);
  }
  return themeId;
};

export const fetchCartDetails = (
  setCartItemCount: (cartItemCount: number) => void
): number => {
  let cartItemCount = 0;
  let storageCartDetails = localStorage.getItem(consts.STORAGE_CART);
  if (storageCartDetails) {
    let currentCartDetails: CartDetails = JSON.parse(storageCartDetails);
    currentCartDetails.map((item) => {
      cartItemCount += item.cardDetails.length;
    });
    setCartItemCount(cartItemCount);
  }
  return cartItemCount;
};
