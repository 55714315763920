import React, { useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { Button, Link, IconButton, Badge } from "@mui/material";
import { ShoppingCart as ShoppingCartIcon } from "@mui/icons-material";
import { CodeDetails } from "../../../shared/types";

export interface CartButtonProps {
  cartItemCount: number;
}

export const CartButton = ({
  cartItemCount,
}: CartButtonProps): JSX.Element | null => {
  return (
    <Link href={`/checkout`}>
      <IconButton aria-label="cart">
        <Badge badgeContent={cartItemCount} color="secondary">
          <ShoppingCartIcon />
        </Badge>
      </IconButton>
    </Link>
  );
};
