import { ThemeOptions } from "@mui/material/styles";

export const light: ThemeOptions = {
  palette: {
    mode: "light",
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          "& h2": {
            fontFamily: "Lato",
            paddingTop: "12px",
            paddingBottom: "6px",
          },
          "& h4": {
            fontFamily: "Lato",
            color: "#272643",
            fontSize: "1.3rem",
            textTransform: "initial",
          },
          "& h5": { fontFamily: "Lato" },
          "& label": { fontFamily: "Lato" },
          "& input": { fontFamily: "Lato" },
          "& p": { fontFamily: "Lato" },
          "& button": { fontFamily: "Lato" },
          "& div": { fontFamily: "Lato" },
          "& span": { fontFamily: "Lato" },
          "& .MuiDialog-paper": { maxWidth: "525px" },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "& svg": { color: "#808080" },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontWeight: "700",
          fontFamily: "Lato",
          textTransform: "none",
          "&.tb_spend_button": {
            border: "1px solid #f26820",
            borderRadius: "32px",
            padding: "8px 16px",
            margin: "8px",
            color: "#ffffff",
            backgroundColor: "#f26820",
            "&:hover": {
              borderColor: "#ff7f3b",
              backgroundColor: "#ff7f3b",
            },
          },
          "&.tb_spend_validated_button": {
            border: "2px solid #f26820",
            borderRadius: "32px",
            padding: "8px 16px",
            margin: "8px",
            color: "#ffffff",
            backgroundColor: "#f26820",
            "&:hover": {
              borderColor: "#ff7f3b",
              backgroundColor: "#ff7f3b",
            },
          },
          "&.tb_buy_button": {
            border: "1px solid #272643",
            borderRadius: "32px",
            padding: "8px 16px",
            margin: "8px",
            color: "#ffffff",
            backgroundColor: "#272643",
            "&:hover": {
              borderColor: "#2c698d",
              backgroundColor: "#2c698d",
            },
          },
          "&.tb_buy_disabled_button": {
            border: "2px solid #272643",
            borderRadius: "32px",
            padding: "8px 16px",
            margin: "8px",
            color: "#272643",
            backgroundColor: "#ffffff",
            "&:hover": {
              borderColor: "#2c698d",
              color: "#2c698d",
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: "0px",
          "& span.MuiFormControlLabel-label": { marginLeft: "6px" },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label": { color: "rgba(0, 0, 0, 0.87)" },
          "& input": {
            border: "none",
            color: "rgba(0, 0, 0, 0.87)",
          },
          "& p": { color: "rgba(0, 0, 0, 0.87)" },
          "& MuiFormHelperText-root": { marginTop: "6px" },
          "& .tb_search_label": {
            top: "0px",
          },
          "& .tb_search_input input": {
            height: "56px",
          },
          "& .tb_dialog_input input": {
            height: "40px",
          },
          "& .tb_paper_input input": {
            height: "56px",
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          "& button.tb_action_button": {
            borderRadius: "4px",
            borderStyle: "solid",
            borderWidth: "1px",
            padding: "5px 15px",
            height: "36.5px",
            borderColor: "#f26820",
            backgroundColor: "#f26820",
            color: "#ffffff",
            "&:hover": {
              borderColor: "#ff7f3b",
              backgroundColor: "#ff7f3b",
            },
          },
          "& button.Mui-disabled": {
            borderColor: "#b4b4b4",
            backgroundColor: "#b4b4b4",
            color: "#ffffff",
            "&:hover": {
              borderColor: "#b4b4b4",
              backgroundColor: "#b4b4b4",
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "& li": {
            display: "block",
            padding: "6px",
          },
          "& button.tb_action_button": {
            borderRadius: "4px",
            borderStyle: "solid",
            borderWidth: "1px",
            padding: "5px 15px",
            height: "36.5px",
            borderColor: "#f26820",
            backgroundColor: "#f26820",
            color: "#ffffff",
            "&:hover": {
              borderColor: "#ff7f3b",
              backgroundColor: "#ff7f3b",
            },
          },
          "& button.tb_cancel_button": {
            borderRadius: "4px",
            borderStyle: "solid",
            borderWidth: "1px",
            padding: "5px 15px",
            height: "36.5px",
            marginRight: "8px",
            borderColor: "#f26820",
            backgroundColor: "#ffffff",
            color: "#f26820",
            "&:hover": {
              borderColor: "#ff7f3b",
            },
          },
          "& button.tb_secondary_button": {
            borderRadius: "4px",
            borderStyle: "solid",
            borderWidth: "1px",
            padding: "5px 15px",
            height: "36.5px",
            marginRight: "8px",
            borderColor: "#f26820",
            backgroundColor: "#ffffff",
            color: "#f26820",
            "&:hover": {
              borderColor: "#ff7f3b",
            },
          },
          "& button.Mui-disabled": {
            borderColor: "#b4b4b4",
            backgroundColor: "#b4b4b4",
            color: "#ffffff",
            "&:hover": {
              borderColor: "#b4b4b4",
              backgroundColor: "#b4b4b4",
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& div.MuiDialogContent-root": {
            paddingTop: "16px",
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          "& button": {
            borderRadius: "4px",
            borderStyle: "solid",
            borderWidth: "1px",
            padding: "5px 15px",
            height: "36.5px",
            borderColor: "#f26820",
            backgroundColor: "#f26820",
            color: "#ffffff",
            "&:hover": {
              borderColor: "#ff7f3b",
              backgroundColor: "#ff7f3b",
            },
          },
          "& button.Mui-disabled": {
            borderColor: "#b4b4b4",
            backgroundColor: "#b4b4b4",
            color: "#ffffff",
            "&:hover": {
              borderColor: "#b4b4b4",
              backgroundColor: "#b4b4b4",
            },
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          "& button": {
            borderRadius: "4px",
            borderStyle: "solid",
            borderWidth: "1px",
            padding: "5px 15px",
            height: "36.5px",
            borderColor: "#f26820",
            backgroundColor: "#f26820",
            color: "#ffffff",
            "&:hover": {
              borderColor: "#ff7f3b",
              backgroundColor: "#ff7f3b",
            },
          },
          "& button.Mui-disabled": {
            borderColor: "#b4b4b4",
            backgroundColor: "#b4b4b4",
            color: "#ffffff",
            "&:hover": {
              borderColor: "#b4b4b4",
              backgroundColor: "#b4b4b4",
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#272643",
          "&:hover": { color: "#f26820" },
        },
      },
    },
  },
};
